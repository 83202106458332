let datatable = $('.datatable').DataTable({
    //"scrollX": true,
    responsive: true,
    serverSide: true,
    "dom": '<"top"fl<"clear">>rt<"bottom"ip<"clear">>',
    "oLanguage": {
      "sSearch": "",
      "sLengthMenu": '_MENU_'
    },
    deferRender: true,
    ajax: {
        "url": '/',
        "type": "POST",
        "headers": {
            'X-OCTOBER-REQUEST-HANDLER':'onLoadData'
        },
    },
    "columnDefs": [
        {
            "targets": 5,
            "render": function (data, type, full, meta) {
                if (data == "trad") {
                    return '<span class="text-green">Trad</span>';
                } else if (data == "non trad") {
                    return '<span class="text-red">Non trad</span>';
                }
                return data;
                }
        },
        {
            "targets": 13,
            "render": function (data, type, full, meta) {
                return '<a onClick="window.open(\'/property/' + data + '\'); return false;" class="label label-warning" target="_blank">View more</a>';
            }
        }
    ],
    "initComplete": (settings, json) => { 
      $('div.dataTables_filter input').attr('placeholder', 'Search...');

      // $(".dataTables_wrapper select").select2({
      //   minimumResultsForSearch: Infinity
      // });
    }

  });


$('div.dataTables_filter input').unbind();

$('div.dataTables_filter input').bind('keyup', function(e) {
    if(e.keyCode == 13) {
        datatable.search(this.value).draw();
    }
});

var sNoMatch = "e817198717e0db0875e788f1549e5d43",
    $searchInput = $('.dataTables_filter input'),
    searchInputVal;

datatable.search(sNoMatch).draw();

$searchInput.on( 'input', function() {
    searchInputVal = this.value;
});

datatable.on( 'search.dt', function () {
    if (datatable.search() == '') datatable.search(sNoMatch);
});

datatable.on( 'order.dt', function () {
    if (datatable.search() == '') datatable.search(sNoMatch);
    $searchInput.val(searchInputVal);
});

$searchInput.val("");
